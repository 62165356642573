import { Box, Flex, Text } from "@chakra-ui/react";
import big_banner from "../Assests/Banner.png";
import "./header.css";
import Navbar from "./Navbar";
import SmallNavbar from "./SmallNav";

function Banner() {
  return (
    <>
      <SmallNavbar />
      <Box
        display="flex"
        position="relative"
        width="98%"
        height={{ base: "400px", lg: "580px" }}
        bgImage={`url(${big_banner})`}
        bgSize="cover"
        bgPosition="center"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Navbar />
        <Flex
          position="absolute"
          flexDirection="column"
          width={{ base: "100%", lg: "40%" }}
          alignItems="center"
        >
          <Text
            fontWeight={900}
            fontSize={{ base: "1.5rem", lg: "2.4rem" }}
            fontFamily="EkMukta"
            p="4"
            color="#F56A02"
            marginBottom="37%"
            textAlign="center"
          >
            समाज समृद्ध-देश समृद्ध
          </Text>
          {/* Uncomment and edit the below Text component if needed */}
          {/* <Text
            textAlign="center"
            fontSize={{ base: "1.1rem", lg: "1.5rem" }}
            fontWeight={{ base: 600, lg: 500 }}
            fontFamily="EkMukta"
            p="4"
          >
            स्वर्गीय सुरेश सिंह फाउंडेशन® ट्रस्ट का मुख्य उद्देश्य समृद्ध समाज हेतु सामाजिक मुद्दों के प्रति लोगों में जागरूकता उत्पन्न कर सभ्य समाज बनाना है।
          </Text> */}
        </Flex>
      </Box>
    </>
  );
}

export default Banner;
